.sphere-wrapper {
    background: rgba(170, 238, 228, 0.1);
    float: right;
    width: 47%;
    height: 100%;
    border-radius: 20px 0 0 20px;
    max-width: 45%;
    position: relative;
    overflow: hidden;
}

.text-sphere {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    animation: rotateIn 1s 1s;
    animation-fill-mode: forwards;
}

.tagcloud {
    display: inline-block;
    top: 0;
    left: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    letter-spacing: 0.0625em;
    font-size: 1.3em;

    .tagcloud--item {
        color: #00ffffff;
        text-transform: uppercase;

        &:hover {
            color: #ff6347;
        }
    }
}

.flat-button {
    color: #D6CCCC;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;
    transition: all .5s ease-in-out;

    &:hover {
        transition: all .5s ease-in-out;
        background-color: #ffd700;
        color: #333;
    }
}

@media screen and (max-width: 1200px) {
.text-sphere {
    position: absolute;
    
}
.sphere-wrapper {
    display: none;
    position: absolute;
    max-width: 100%;
    height: 90vh;
    width: 100%;
    top: 0;
    opacity: .3;
    z-index: -10;
    .text-sphere{
        position: absolute;
        top: 15%;
        z-index: -1000;
        div {
            .tagcloud {
                z-index: -1000;
            }
        }
    }
}
.skills-page .text-zone {
    height: 90vh;
    z-index: 10;
    overflow-y: scroll;
    p {
        background-color: rgba(170, 238, 228, 0.1);
        padding: 10px;
        border-radius: 20px;
    }
}



}


@media screen and (max-height: 900px) {
    .skills-page.container {
        height: 85vh;
        overflow-y: scroll;
    }
}