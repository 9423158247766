.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: #1c7db1;
        height: 50px;
        font-size: 16px;
        color: white;
        padding: 0 20px;
        box-sizing: border-box;
        
        &::placeholder {
            color: white;
        }
    }

    textarea {
        width: 100%;
        border: 0;
        background: #1c7db1;
        font-size: 16px;
        color: #fff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;

        &::placeholder {
            color: white;
        }
    }

    .flat-button {
        color: #D6CCCC;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        transition: all .5s ease-in-out;
        background-color: transparent;
        cursor: pointer;
        &:hover{
            transition: all .5s ease-in-out;
            background-color: #ffd700;
            color: #333;
        }
        
    }
}

.info-panel {
    position: absolute;
    background-color: black;
    top: 10%;
    right: 30%;
    z-index: 99999;
    width: 267px;
    padding: 20px;
    color: white;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s;
    animation-fill-mode: forwards;

    span {
        font-size: 16px;
        display: block;
        padding-top: 20px;
        color: #ffd700;
    }
}

.map-wrapper {
    background: rgba(8,253,216,0.1);
    float: right;
    width: 47%;
    height: 100%;
}

.leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
}



@media screen and (max-width: 1200px) {
    .info-panel {
        display: none;
    }

    .map-wrapper {
        display: none;
    }


}