.react-multi-carousel-track {
    height: 100%;

    .react-multi-carousel-item {

        .project-wrapper {
            display: block;
            justify-content: center;
            align-items: center;
            gap: 20px;
            transition: opacity 0.5s 0.5s ease-in-out;

            a {
                transition: opacity 0.5s 0.5s ease-in-out;
            }

            .opacityNone {
                opacity: 0;
                transition: opacity 0.5s 0.5s ease-in-out;

            }

            .displayNone {
                display: none;
                transition: display 0.5s 0.5s ease-in-out;

            }

            img {
                margin-top: 40px;
                max-width: 90%;
                max-height: 100%;
                position: absolute;
                object-fit: cover;
                top: 150px;
                -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
                transform-origin: center;
                transform: perspective(800px) rotateY(25deg);
                transition: transform 1s ease-in-out, opacity 1s ease-in-out;
            }

            .image2 {
                opacity: 0;
            }
            .description-container {
                position: absolute;
                bottom: 5%;

                .info {
                    position: relative;
                    right: 25px;
                    padding: 25px;
                    background-color: rgba($color: #888c8d, $alpha: 0.5);
                    text-align: center;
                    border-radius: 30px;
                    margin: 50px;

                    h2 {
                        margin: 0 auto;
                        padding: 0px 20px 0px 20px;
                        border-radius: 30px;
                        color: rgb(51, 196, 253);
                        background-color: #525353;
                        width: fit-content;
                        text-align: center;
                        font-size: 3em;
                    }

                    p {
                        margin: 0px auto 0px 0px;
                        color: white;
                        font-size: 2em;
                    }
                }

                .buttons {
                    position: absolute;
                    bottom: -40px;

                    .flat-button {
                        background-color: transparent;
                        color: #D6CCCC;
                        font-size: 13px;
                        font-weight: 400;
                        letter-spacing: 4px;
                        font-family: sans-serif;
                        text-decoration: none;
                        padding: 10px 18px;
                        border: 1px solid #ffd700;
                        margin-top: 25px;
                        float: left;
                        animation: fadeIn 1s 1.8s backwards;
                        white-space: nowrap;
                        transition: all .5s ease-in-out;

                        &:hover {
                            transition: all .5s ease-in-out;
                            background-color: #ffd700;
                            color: #333;
                            cursor: pointer;
                        }

                    }

                }
            }


            &:hover,
            &:focus {
                img {
                    opacity: 0.3;

                    &:hover,
                    &:focus {
                        transform: perspective(800px) rotateY(0deg);
                        opacity: 1;
                    }
                }
            }
        }

    }
}