.nav-bar {
    background: #181818;
    width: 80px;
    height: 100%;
    position: absolute;
    z-index: 3;
    min-height: 500px;

    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 8px auto;
            width: 40px;
            height: auto;

            &.sub-logo {
                width: 75px;
            }
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 45%;
        margin-top: -120px;
        width: 100%;

        a {
            font-size: 40px;
            color: #D6CCCC;
            display: block;
            line-height: 71px;
            height: 71px;
            position: relative;
            text-decoration: none;

            svg {
                opacity: 1;
                transition: opacity 0.5s ease-in-out
            }

            &:hover {

                color: #D6CCCC;

                svg {
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;
                }


                &:after {
                    transition: all 0.5s ease-in-out;

                    opacity: 1;
                }

                transition: all 0.5 ease-in-out;
            }

            &:after {
                content: '';
                font-size: 12px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                opacity: 0;
                text-align: center;
                -webkit-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
            }


            &:first-child {
                &::after {
                    content: 'HOME';
                }
            }
        }
    }

    a.about-link {
        &:after {
            content: 'ABOUT';
        }
    }

    a.contact-link {
        &:after {
            content: 'CONTACT';
        }
    }

    a.skills-link {
        &:after {
            content: 'SKILLS';
        }
    }

    a.projects-link {
        &:after {
            content: 'PROJECTS';
        }
    }

    a.active {
        svg {
            color: #D6CCCC;
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: none;
        margin: 0;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 25px;
                line-height: 16px;
                text-align: center;
            }

            svg {
                color: #4d4d4e;
                transition: color 0.3s ease-in-out;
            }

            &:hover {
                svg {
                    color: #D6CCCC;
                    transition: color 0.3s ease-in-out;
                }
            }
        }

        .tooltip {
            position: relative;
            display: block;
            border-bottom: 1px dotted black;


            .tooltiptext {
                visibility: hidden;
                width: fit-content;
                background-color: black;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 5px;

                /* Position the tooltip */
                position: absolute;
                bottom: 0;
                left: 65px;
                z-index: 1;
                opacity: 0;
                transition: all 0.5s ease-in-out;
            }

            &:hover {
                .tooltiptext {
                    visibility: visible;
                    opacity: 1;
                    transition: all 1s ease-in-out;
                }
            }

        }
    }
}

.hamburger-icon,
.close-icon {
    display: none;
}

@media screen and (max-width: 1200px) {
    .nav-bar {
        // background: transparent;
        position: initial;
        height: auto;
        min-height: auto;
        width: 100%;
        z-index: 100;

        .logo {
            width: fit-content;
            img {
                position: relative;
                left: 15px;
                width: 30px;
            }

            .sub-logo {
                display: none;
            }
        }

        ul,
        nav {
            display: none;
        }

        nav {
            width: 100dvw;
            top: 0;
            left: 0;
            background: #181818;
            height: fit-content;
            margin: 0;
            z-index: 2;
            position: fixed;
            z-index: 10;

            &.mobile-show {
                display: block;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            a:after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
            }

            a svg {
                opacity: 1 !important;
            }


        }
        ul {
            width: 100%;
            bottom: 0;
            left: 0;
            background: #181818;
            height: fit-content;
            margin: 0;
            z-index: 2;
            position: fixed;
            z-index: 10;

            &.mobile-show {
                display: block;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            a:after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
            }

            a svg {
                opacity: 1 !important;
            }


        }

        ul {
            &.mobile-show {
                display: block;
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #D6CCCC;
                    font-size: 12px;
                    letter-spacing: 3px;
                    text-decoration: none;
                }
    
                a:after {
                    opacity: 1;
                    position: initial;
                    width: auto;
                    margin-left: 10px;
                }
    
                a svg {
                    height: 2.5em;
                    opacity: 1 !important;
                }

                li.resume > a {
                    &:after {
                        content: 'RESUME';
                    }
                }
            
                li.linkedin > a {
                    &:after {
                        content: 'LINKEDIN';
                    }
                }
            
                li.github > a {
                    &:after {
                        content: 'GITHUB';
                    }
                }
            
                a.projects-link {
                    &:after {
                        content: 'PROJECTS';
                    }
                }
            }
        }
     

        .hamburger-icon,
        .close-icon {
            position: absolute;
            top: 20px;
            right: 27px;
            display: block;
        }
    }
}