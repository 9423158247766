.logo-container {
    z-index: -2;
    width: 600px;
    height: 809px;
    position: absolute;
    top: 0;
    right: 15%;
    left: auto;
    margin: auto;
    opacity: 1;
}

svg.logo {
    width: 150%;
    height: auto;
    bottom: 0;
    z-index: 1;
    transform: rotateZ(30deg) !important;
}

.solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: -11.7%;
    left: 25.8%;
    margin: auto;
    width: 95%;
    opacity: 0;
    transform: rotateZ(30deg);
    z-index: 1;

}

.svg-container {
    stroke: #D6CCCC;
    
}


