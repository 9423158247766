$primary-color: #d6cccc;

@import 'animate.css';
@import '~loaders.css/src/animations/cube-transition.scss';

// Establishing Custom Fonts
@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff') format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

// Establishing Default Fonts

input,
textarea {
  font-family: 'Helvetica Neue';
}

.loader-wrapper {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: -10;
  .loader-active {
    display: block;
    margin: 0 auto;
    position: absolute;
    opacity: 1;
    animation: fadeOut .5s 1s;
    animation-fill-mode: forwards;

    &::before {
      content: 'Ethan';
      position: absolute;
      height: 100%;
      bottom: 80px;
      margin: 0 auto;
      left: 50%;
      transform: translate(-50%, -25px);
      font-size: 30px;
      color: white;
    }
    &::after {
      content: 'Amato';
      position: absolute;
      top: 100px;
      left: 50%;
      transform: translate(-50%, -25px);
      margin: 0 auto;
      font-size: 30px;
      color: white;
    }
  }
}
