.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags {
    bottom: auto;
    top: 35px;
    height: 100px;
}


.tags {
    color: #D6CCCC;
    opacity: .6;
    position: absolute;
    bottom: 5%;
    left: 140px;
    font-size: 24px;
    font-family: 'La Belle Aurore';
}


.html-tag{
    margin-left: -20px;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90vh;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.about-page,.contact-page,.skills-page,.projects-page {
    .text-zone {
        position: absolute;
        left: 15%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;
    }


    h1 {
        font-size: 53px;
        font-family: 'sans-serif';
        color: #D6CCCC;
        position: relative;
        margin-bottom: 40px;
        left: 10px;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #D6CCCC;
            font-size: 24px;
            position: absolute;
            margin-top: -10px;
            left: -10px;
            line-height: 18px;
            opacity: .6;
        }
        &::after {
            content: '</h1>';
            font-family: 'La Belle Aurore';
            color: #D6CCCC;
            font-size: 24px;
            position: absolute;
            left: -10px;
            bottom: -30px;
            opacity: .6;
            line-height: -18px;

        }
    }
    p {
        font-size: 20px;
        color: white;
        font-weight: 100;
        min-width: fit-content;
        animation: pulse 1s;

        @for $i from 1 through 3 {
            &:nth-of-type(#{$i}) {
                animation-delay: #{calc(1.1 + ($i * 0.1))}s;
            }
        }
    }

    .text-animate-hover {
        &:hover {
            color: #D6CCCC;
        }
    }
}


@media screen and (max-width: 1200px) {
    .page {
      position: initial;
      min-height: 100dvh;
    }
    
    .container {
      position: inherit;
      min-height: fit-content;
      overflow-y: scroll;
      &.contact-page,
      &.skills-page,
      &.about-page,
      &.projects-page {
        .text-zone {
          position: initial;
          transform: none;
          width: 100%;
          display: block;
          padding: 20px;
          padding-top: 0;
          box-sizing: border-box;
        }
      }
      &.contact-page {
        .flat-button {
          margin-top: 0;
          margin-bottom: 100px;
        }
       }
  
      &.projects-page {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
  
        h1.page-title {
          margin-left: 20px;
          margin-top: 20px;
        }
  
        .image-box {
          height: 200px;
          max-width: calc(50% - 10px);
        }
      }
    }
   
    // .stage-cube-cont {
    //   position: initial;
    //   width: 100%;
    //   height: 100vh;
    //   overflow: visible;
    // }
  }