.home-page {

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
        max-height: 90%;
        
       
    }

    h1 {
        color: white;
        font-size: 58px;
        margin: 0;
        font-weight: 400;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #D6CCCC;
            font-size: 24px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: .6;
        }
        &::after {
            content: '</h1>';
            font-family: 'La Belle Aurore';
            color: #D6CCCC;
            font-size: 24px;
            position: absolute;
            margin-top: 18px;
            left: 15px;
            opacity: .6;

        }
        img {
            width: 50px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s ease both;
            animation-delay: 1.4s;
        }


        .big-E {
            position: relative;
            top: 10px; 
        }
    }

    h2 {
        color: #c1c1c1;
        width: fit-content;
        // margin-top: 45px;
        margin: 45px auto auto auto;
        text-align: center;
        font-weight: 400;
        font-size: 2em;
        letter-spacing: 3px;
        padding: 0px 10px 0px 10px;
        animation: fadeIn 1s 1.8s backwards;
        animation: fadeInBackgroundColor 2s 1.8s forwards;
        border-radius: 4em;
        @keyframes fadeInBackgroundColor {
            from{background-color: none;}
            to{background-color: #50450a;}
            
        }
    }

    .flat-button {
        color: #D6CCCC;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        transition: all .5s ease-in-out;

        &:hover{
            transition: all .5s ease-in-out;
            background-color: #ffd700;
            color: #333;
        }
    }
}


@media screen and (max-width: 1200px) {

    .home-page {

        .flat-button {
            background-color: #333;
        }
        
        h1 {
            background-color: rgba(#333, 0.8);
            padding: 20px;
            border-radius: 1em;
            width: fit-content;
            margin: 0 auto;
            span {
                font-size: 40px;
            }
        }
        // h2 {
        //     ;
        // }
        
        .logo-container {
            position: relative;
            // left: 0;
            margin: 0 auto;
            width: 300px;
            // height: ;
        }
        
        svg.logo {
            position: absolute;
            width: 150%;
            height: auto;
            margin: 0 auto;
            top: 20px;
            left: 10px;
            z-index: 1;
            transform: rotateZ(0deg) !important;
        }
        
        .solid-logo {
            position: absolute;
            top: 0;
            margin: 0 auto;
            width: 100%;
            opacity: 0;
            transform: rotateZ(0deg) !important;
            z-index: 1;
            
        }

        .text-zone {
            position: absolute;
            left: 0;
        }
    }
}